<template>
  <div class="earn-footer-container">
    <span class="earn-footer-text">{{ $t("landing.promotionText") }}<a
        :href="`${promotionTandC}`"
        target="_blank"
        class="promotion-text promotion-tc"
    >{{ $t("landing.promotionTC") }}</a></span>
    <v-btn class="button-size-footer" @click="$router.push({name: 'landing'})" color="primary">{{
        $t("earnWithBkash.learnMore")
      }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "FooterContainer",
  computed: {
    promotionTandC() {
      return process.env.VUE_APP_TERMS_AND_CONDITION_TERMS_AND_CONDITION_URL || '';
    },
  },
}
</script>

<style lang="scss" scoped>

$LaptopW: 1440px;
$tabLS: 900px;
$mobileW: 750px;
$xSmallW: 390px;

.earn-footer-container {
  display: flex;
  width: 100%;
  height: 105px;
  align-items: center;
  background: #E2136E;
  justify-content: center;
  @media (min-width: 320px) and (max-width: $mobileW) {
    flex-direction: column;
    height: 130px;
  }
  @media (min-width: 901px) and (max-width: $LaptopW) {
    height: 98px;
  }

  .earn-footer-text {
    font-size: 40px;
    font-weight: 500;
    color: #FFFFFF;
    @media (max-width: $LaptopW) {
      font-size: 30px;
    }
    @media (max-width: $tabLS) {
      font-size: 22px;
      text-align: center;
    }
    @media (max-width: $xSmallW) {
      font-size: 17px;
      text-align: center;
      margin-bottom: 10px;
    }

    .promotion-text {
      color: #FFFFFF;
      font-weight: 500;
      @media (max-width: $mobileW) {
        font-weight: bold;
        margin-bottom: 5px;
        text-align: center;
      }
    }

    .promotion-tc {
      text-decoration: underline;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 5px;
    }
  }

  .button-size-footer {
    width: 222px;
    height: 57px;
    margin-left: 55px;
    font-size: 24px !important;
    @media (max-width: $LaptopW) {
      width: 160px !important;
      height: 45px !important;
      margin-left: 20px;
    }
    @media (max-width: $mobileW) {
      font-size: 18px !important;
    }
  }
}
</style>
