<template>
  <v-card class="header-container">
    <div class="container item-container">
      <div class="header-left-div">
        <img class="earning-bkash-logo" src="@/assets/image/earning-bkash-logo.png" alt="img">
        <span class="dashboard-text" @click="$router.push({name: 'landing'})">Business Dashboard</span>
      </div>
      <div class="drop-down-div">
        <v-menu
            transition="slide-y-transition"
            bottom>
          <template v-slot:activator="{ on, attrs }">
            <div style="display: flex; flex-direction: row;" v-bind="attrs" v-on="on">
              <span class="language-text">
                {{ lang }}
              </span>
              <img class="drop-down-arrow" src="@/assets/image/drop-down-white-icon.svg" alt="img">
            </div>
          </template>
          <v-list>
            <v-list-item
                v-for="(item, i) in languages"
                :key="i">
              <v-list-item-title class="drop-down-item" @click="changeLang(item)">
                {{ item }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

  </v-card>
</template>

<script>
export default {
  name: "HeaderContainer",
  data() {
    return {
      languages: ['eng', 'বাংলা'],
      lang: this.$i18n.locale || 'বাংলা'
    }
  },
  methods: {
    changeLang(language) {
      this.lang = language;
      this.$i18n.locale = language
      localStorage.setItem('lang', language);
    }
  },
}
</script>

<style lang="scss" scoped>
$LaptopW: 1440px;
$tabLS: 900px;
$mobileW: 750px;
.header-container {
  background-color: #e2136e;
  width: 100%;

  .item-container {
    display: flex;
    width: 100%;
    height: 86px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: $mobileW) {
      height: 70px;
    }

    .header-left-div {
      display: flex;
      flex-direction: row;
      align-items: center;

      .earning-bkash-logo {
        width: 116.7px;
        height: 53.6px;
        @media (max-width: $mobileW) {
          width: 60px;
          height: 30px;
        }
      }

      .dashboard-text {
        font-size: 23px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #FFFFFF;
        margin-left: 34px;
        cursor: pointer;
        @media (max-width: $mobileW) {
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }

    .drop-down-div {
      display: flex;
      flex-direction: row;
      align-items: center;

      .language-text {
        text-transform: capitalize !important;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #FFFFFF;
        margin-top: 5px;
      }

      .drop-down-arrow {
        height: 18px;
        width: 20px;
        margin: 5px;
        cursor: pointer;
      }
    }
  }
}

.drop-down-item {
  cursor: pointer;
  text-transform: capitalize !important;
}
</style>
