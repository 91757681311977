<template>
  <div class="earn-page-container">
    <header-container></header-container>
    <div class="body-container">
      <div/>
      <span class="heading-text">{{ $t("earnWithBkash.title") }}</span>
      <span class="moto-text">{{ $t("earnWithBkash.advertisementText") }}</span>
      <v-btn color="primary" class="button-size-landing-body"
             @click="$router.push({name: 'sign-up'});">
        {{ $t("earnWithBkash.signup") }}
      </v-btn>
      <iframe class="video-container"
              :src="videoURL">
      </iframe>
      <span class="description-text">{{ $t("earnWithBkash.descriptionText") }}</span>
    </div>
    <footer-container></footer-container>
  </div>
</template>

<script>

import HeaderContainer from "@/components/earnwithDashboard/HeaderContainer";
import FooterContainer from "@/components/earnwithDashboard/FooterContainer";

export default {
  name: "EarnWithDashboard",
  components: {
    HeaderContainer,
    FooterContainer,
  },
  computed: {
    videoURL() {
      return process.env.VUE_APP_BKASH_VIDEO_URL;
    },
  }
}
</script>

<style lang="scss" scoped>

$LaptopW: 1440px;
$MedW: 1024px;
$tabLS: 900px;
$mobileW: 750px;

.earn-page-container {
  background-color: #FFFFFF;
  height: 100%;
  background-image: url('../../assets/image/conversion-landing/conversion-landing-1920.png');
  background-size: cover;
  @media (max-width: $MedW) {
    width: 100%;
    background-size: 100% 100%;
  }
  @media (max-width: $mobileW) {
    background-image: url('../../assets/image/conversion-landing/conversion-landing-phone.png');
    background-size: cover;
  }

  .body-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 81%;
    flex-direction: column;
  }

  .video-container {
    width: 626px;
    min-height: 325px;
    @media (max-width: $LaptopW) {
      min-height: 300px;
    }
    @media (max-width: $mobileW) {
      width: 90%;
      min-height: 200px;
    }
  }

  .button-size-landing-body {
    width: 312px;
    height: 80px;
    margin: 35px 0 28px 0;
    font-size: 33px !important;
    @media (max-width: $mobileW) {
      width: 80% !important;
      height: 60px !important;
      font-size: 25px !important;
    }
    @media (min-width: $mobileW) and (max-width: $LaptopW) {
      height: 70px !important;
      font-size: 30px !important;
      margin: 10px 0 28px 0;
    }
  }

  .heading-text {
    font-size: 65px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    margin: 47px 10px 16px 10px;
    @media (max-width: $LaptopW) {
      font-size: 50px;
      margin: 25px 10px 10px 10px;
    }
    @media (max-width: $mobileW) {
      font-size: 30px;
    }
  }

  .moto-text {
    font-size: 40px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    margin: 0px 10px 0px 10px;
    @media (max-width: $LaptopW) {
      font-size: 30px;
    }
    @media (max-width: $mobileW) {
      font-size: 20px;
    }
  }

  .description-text {
    font-size: 40px;
    margin: 28px 10px 21px 10px;
    color: #e2136e;
    text-align: center;
    @media (max-width: $LaptopW) {
      font-size: 30px;
      margin: 15px 10px 14px 10px;
    }
    @media (max-width: $mobileW) {
      font-size: 20px;
    }

  }
}
</style>
